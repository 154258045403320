import { useEffect, useState } from "react";
import Header from "../../components/Header";
import Layout from "../../components/layout/Layout";
import { useStateContext } from "../../contexts/NavProvider";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function Dashboard() {
  const axios = useAxiosPrivate();
  const { darkMode, setLoading } = useStateContext();
  const [reports, setReports] = useState<any>({});

  useEffect(() => {
    const getReports = async () => {
      await axios
        .get(`${process.env.REACT_APP_API}/reports`)
        .then((response) => {
          console.log(response.data);
          setReports(response.data);

          // const user = response.data;
        });
    };
    getReports();
  }, []);

  return (
    <Layout>
      <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl dark:bg-slate-700">
        <Header category="Page" title="Dashboard"></Header>
        <p className="text-2xl font-bold text-black dark:text-white">
          Últimos 7 dias
        </p>

        <div className="sm:flex sm:space-x-4">
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
            <div className="bg-white dark:bg-slate-700 p-5">
              <div className="sm:flex sm:items-start">
                <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                  <h3 className="text-sm leading-6 font-medium text-gray-400 dark:text-gray-400">
                    Novos Clientes
                  </h3>
                  <p className="text-3xl font-bold text-black dark:text-white">
                    {reports.customers7Days && reports.customers7Days}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
            <div className="bg-white dark:bg-slate-700 p-5">
              <div className="sm:flex sm:items-start">
                <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                  <h3 className="text-sm leading-6 font-medium text-gray-400">
                    Total em Vendas
                  </h3>
                  <p className="text-3xl font-bold text-black dark:text-white">
                    {reports.total7Days
                      ? parseFloat(reports.total7Days).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                          minimumFractionDigits: 2,
                        })
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
            <div className="bg-white dark:bg-slate-700 p-5">
              <div className="sm:flex sm:items-start">
                <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                  <h3 className="text-sm leading-6 font-medium text-gray-400">
                    Total de Cashback Disponível
                  </h3>
                  <p className="text-3xl font-bold text-black dark:text-white">
                    {reports.cashback7Days
                      ? parseFloat(reports.cashback7Days.total).toLocaleString(
                          "pt-BR",
                          {
                            style: "currency",
                            currency: "BRL",
                            minimumFractionDigits: 2,
                          }
                        )
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className="text-2xl font-bold text-black dark:text-white">
          Últimos 14 dias
        </p>
        <div className="sm:flex sm:space-x-4">
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
            <div className="bg-white dark:bg-slate-700 p-5">
              <div className="sm:flex sm:items-start">
                <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                  <h3 className="text-sm leading-6 font-medium text-gray-400 dark:text-gray-400">
                    Novos Clientes
                  </h3>
                  <p className="text-3xl font-bold text-black dark:text-white">
                    {reports.customers14Days && reports.customers14Days}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
            <div className="bg-white dark:bg-slate-700 p-5">
              <div className="sm:flex sm:items-start">
                <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                  <h3 className="text-sm leading-6 font-medium text-gray-400">
                    Total em Vendas
                  </h3>
                  <p className="text-3xl font-bold text-black dark:text-white">
                    {reports.total14Days
                      ? parseFloat(reports.total14Days).toLocaleString(
                          "pt-BR",
                          {
                            style: "currency",
                            currency: "BRL",
                            minimumFractionDigits: 2,
                          }
                        )
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
            <div className="bg-white dark:bg-slate-700 p-5">
              <div className="sm:flex sm:items-start">
                <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                  <h3 className="text-sm leading-6 font-medium text-gray-400">
                    Total de Cashback Disponível
                  </h3>
                  <p className="text-3xl font-bold text-black dark:text-white">
                    {reports.cashback14Days
                      ? parseFloat(reports.cashback14Days.total).toLocaleString(
                          "pt-BR",
                          {
                            style: "currency",
                            currency: "BRL",
                            minimumFractionDigits: 2,
                          }
                        )
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Dashboard;

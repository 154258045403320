import { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";

export const RequireAuth = () => {
  const { auth } = useContext(AuthContext);

  console.log("auth: ", auth?.user);

  return auth?.user ? <Outlet /> : <Navigate to="/login" />;
};

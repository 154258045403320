import { Button, Label, Modal, TextInput } from "flowbite-react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useStateContext } from "../../contexts/NavProvider";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function ConfirmSaleModal({
  confirmSaleModal,
  setConfirmSaleModal,
  confirmSale,
  getSales,
}) {
  const axios = useAxiosPrivate();
  const { darkMode, setLoading } = useStateContext();
  const [confirmCode, setConfirmCode] = useState("");

  const handleCloseConfirmSaleModal = () => {
    setConfirmSaleModal(false);
  };

  const handleSubmit = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/sales/confirm`, {
        id_sale: confirmSale,
        confirm_code: confirmCode,
      })
      .then((response) => {
        if (response.status == 200) {
          setConfirmCode("");
          setConfirmSaleModal(false);
          getSales(true);
          toast.success("Venda confirmada com sucesso!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: darkMode ? "dark" : "light",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: darkMode ? "dark" : "light",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      className={
        "flex flex-col w-full min-h-screen overflow-x-hidden" + darkMode
          ? "dark"
          : ""
      }
      size="sm"
      show={confirmSaleModal}
      onClose={handleCloseConfirmSaleModal}
    >
      <Modal.Header>Confirmar Venda</Modal.Header>
      <Modal.Body
        className="block overflow-y-auto"
        style={{ maxHeight: "70vh" }}
      >
        <div className="flex flex-col gap-4">
          <div>
            <div className="mb-2 block">
              <Label htmlFor="phone" value="Numero" />
            </div>
            <TextInput
              id="code"
              type="text"
              placeholder="Apenas numeros"
              required={true}
              minLength="4"
              onChange={(e) => setConfirmCode(e.target.value)}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit}>Enviar</Button>
        <Button color="gray" onClick={handleCloseConfirmSaleModal}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmSaleModal;

import { useRef, useReducer, useEffect, useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useStateContext } from "../../contexts/NavProvider";
import Layout from "../../components/layout/Layout";

import { toast } from "react-toastify";
import Header from "../../components/Header";
import { Button, Modal, Table, Pagination } from "flowbite-react";
import Creatable from "react-select/creatable";
import { AiOutlineClose } from "react-icons/ai";

import { INITIAL_STATE, contactReducer } from "./contactReducer";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const reader = require("xlsx");

const Contact = () => {
  const { auth } = useContext(AuthContext);
  const { darkMode } = useStateContext();
  const axios = useAxiosPrivate();
  const [state, dispatch] = useReducer(contactReducer, INITIAL_STATE);
  const fileRef = useRef();
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const acceptableFileNames = ["xlsx", "xls"];

  const checkFileName = (name) => {
    return acceptableFileNames.includes(name.split(".").pop().toLowerCase());
  };

  const handleFile = async (e) => {
    const currentFile = e.target.files[0];

    if (!currentFile) return;

    if (!checkFileName(currentFile.name)) {
      alert("Tipo de arquivo invalido!");
      return false;
    }

    const data = await currentFile.arrayBuffer();
    const wb = reader.readFile(data, { raw: true });

    let sheetDataRaw = [];
    let sheetJsonRaw = [];

    const sheets = wb.SheetNames;

    for (let i = 0; i < sheets.length; i++) {
      const temp = reader.utils.sheet_to_json(wb.Sheets[wb.SheetNames[i]], {
        blankrows: "",
        header: 1,
      });
      temp.forEach((res) => {
        sheetDataRaw.push(res);
      });

      const temp2 = reader.utils.sheet_to_json(wb.Sheets[wb.SheetNames[i]], {
        header: ["name", "phone"],
      });
      temp2.forEach((res) => {
        if (res.__rowNum__ === 0 || !res["phone"]) return;

        res["phone"] = res["phone"].toString();
        res["id_user"] = auth.user.id;
        sheetJsonRaw.push(res);
      });
    }

    dispatch({
      type: "SET_FILE_DATA_SUCCESS",
      payload: {
        sheetData: sheetDataRaw,
        sheetJson: sheetJsonRaw,
        fileName: currentFile.name,
      },
    });
  };

  const handleFileUploaded = (e) => {
    console.log(e);
  };

  const handleRemove = (e) => {
    dispatch({
      type: "REMOVE_FILE_DATA",
    });

    fileRef.current.value = "";
  };

  const handleCloseImportModal = (e) => {
    dispatch({
      type: "CLOSE_IMPORT_MODAL",
    });
  };

  const handleClickImport = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/contacts`, {
        contacts: state.sheetJson,
        id_group: selectedGroup?.id,
      })
      .then((response) => {
        dispatch({
          type: "CLOSE_IMPORT_MODAL",
        });
        if (response.status === 201) {
          getContacts();
          toast.success("Contatos importados com sucesso!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: darkMode ? "dark" : "light",
          });
        } else {
          toast.error("error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: darkMode ? "dark" : "light",
          });
        }
      })

      .catch((err) => {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: darkMode ? "dark" : "light",
        });
      })
      .finally(() => {
        //loading
      });
  };

  const getPaginationOptions = () => {
    const options = {
      take: state.perPage,
      id_user: auth.user.id,
    };

    options["skip"] =
      state.currentPage === 1 ? 0 : (state.currentPage - 1) * state.perPage;

    if (state.paginationCursor) options["cursor"] = state.paginationCursor;

    return options;
  };

  const handleOnPageChange = async (e) => {
    dispatch({
      type: "SET_CURRENT_PAGE",
      payload: e,
    });
  };

  const getContacts = async () => {
    console.log(`${process.env.REACT_APP_API}/contacts`);
    await axios
      .get(`${process.env.REACT_APP_API}/contacts`, {
        params: getPaginationOptions(),
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.contacts?.length > 0) {
          dispatch({
            type: "SET_CONTACTS",
            payload: response.data.contacts,
          });
          if (!state.paginationCursor) {
            dispatch({
              type: "SET_PAGINATION",
              payload: {
                paginationCursor: response.data.contacts[0].id,
                totalPages: Math.ceil(response.data.total / state.perPage),
              },
            });
          }
        }
      });
  };

  const getGroups = async () => {
    console.log("get groups");
    await axios
      .get(`${process.env.REACT_APP_API}/groups`)
      .then((response) => {
        console.log(response.data);
        if (response.data?.length > 0) {
          setGroups(response.data);
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const handleCreateGroup = async (inputValue) => {
    console.log("handle");
    await axios
      .post(`${process.env.REACT_APP_API}/groups`, {
        name: inputValue,
      })
      .then((response) => {
        const newOpt = { id: response.data.id, name: response.data.name };
        setGroups((prev) => [...prev, newOpt]);
        setSelectedGroup(newOpt);
        toast.success("Grupo criado com sucesso!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: darkMode ? "dark" : "light",
        });
      })
      .catch(function (err) {
        toast.error("Falha ao criar grupo", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: darkMode ? "dark" : "light",
        });
      });
  };

  useEffect(() => {
    getContacts();
  }, [state.currentPage]);

  useEffect(() => {
    getGroups();
  }, []);

  return (
    <Layout>
      <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl dark:bg-slate-700">
        <Header category="Page" title="Contatos"></Header>
        <div className="mt-12">
          <div className="mb-2">
            {!state.fileName && (
              <label className="text-slate-900 dark:text-white">
                Selecione um arquivo excel
              </label>
            )}
          </div>
          <input
            className="text-slate-900 dark:text-white"
            type="file"
            accept="xlsx, xls"
            multiple={false}
            onChange={(e) => handleFile(e)}
            ref={fileRef}
          />
          {state.fileName && (
            <button
              className="relative text-xl rounded-full p-3 hover:bg-light-gray"
              onClick={handleRemove}
            >
              <AiOutlineClose />
            </button>
          )}
        </div>

        <div className="mt-12">
          {state.contacts && state.contacts.length > 0 && (
            <>
              <div className="flex">
                <Table striped={true}>
                  <Table.Head>
                    <Table.HeadCell>Nome</Table.HeadCell>
                    <Table.HeadCell>Numero</Table.HeadCell>
                  </Table.Head>
                  <Table.Body className="divide-y">
                    {state.contacts.map((contact, i) => (
                      <Table.Row
                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                        key={i}
                      >
                        <Table.Cell>{contact.name}</Table.Cell>
                        <Table.Cell>{contact.phone}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
              {state.totalPages > 1 && (
                <div className="flex">
                  <Pagination
                    currentPage={state.currentPage}
                    totalPages={state.totalPages}
                    layout="pagination"
                    onPageChange={(e) => handleOnPageChange(e)}
                    showIcons={true}
                    previousLabel="Anterior"
                    nextLabel="Proxima"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* {state.importModal && ( */}
      <>
        <Modal
          className={
            "flex flex-col w-full min-h-screen overflow-x-hidden" + darkMode
              ? "dark"
              : ""
          }
          size="7xl"
          show={state.importModal}
          onClose={handleCloseImportModal}
        >
          <Modal.Header>Previa dos Contatos</Modal.Header>
          <Modal.Body
            className="block overflow-y-auto"
            style={{ maxHeight: "70vh" }}
          >
            <Creatable
              isClearable
              onChange={(value) => setSelectedGroup(value)}
              onCreateOption={handleCreateGroup}
              options={groups}
              getOptionLabel={(opt) => opt.name}
              getOptionValue={(opt) => opt.id}
              value={selectedGroup}
            />
            {state.sheetData && state.sheetData.length > 0 && (
              <Table>
                <Table.Head>
                  {state.sheetData[0].map((header, i) => (
                    <Table.HeadCell key={i}>{header}</Table.HeadCell>
                  ))}
                </Table.Head>
                <Table.Body className="divide-y">
                  {state.sheetData.slice(1).map((data, i) => (
                    <Table.Row
                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                      key={i}
                    >
                      <Table.Cell>{data[0]}</Table.Cell>
                      <Table.Cell>{data[1]}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClickImport}>Importar</Button>
            <Button color="gray" onClick={handleCloseImportModal}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      {/* )} */}
    </Layout>
  );
};

export default Contact;

import { useEffect, useState } from "react";
import axios from "../api/axios";
import { AuthContext } from "./AuthContext";
import Cookies from "universal-cookie";

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [auth, setAuth] = useState({});
  const [instance, setInstance] = useState({});
  const cookies = new Cookies();

  const signin = async (email: string, password: string) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API}/auth/login`,
      { email, password },
      { withCredentials: true }
    );
    const { user, accessToken } = data;
    if (user && accessToken) {
      setAuth({ user, accessToken });
      cookies.set("auth.token", accessToken);
      return true;
    }
    return false;
  };

  const signout = async () => {
    try {
      setAuth({});
      const response = await axios.post(
        `${process.env.REACT_APP_API}/auth/logout`,
        {},
        { withCredentials: true }
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AuthContext.Provider
      value={{ auth, setAuth, signin, signout, instance, setInstance }}
    >
      {children}
    </AuthContext.Provider>
  );
};

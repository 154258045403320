export const INITIAL_STATE = {
  loading: false,
  contacts: {},
  file: null,
  fileName: null,
  sheetData: [],
  sheetJson: [],
  importModal: null,
  currentPage: 1,
  perPage: 10,
  totalPages: 1,
  paginationCursor: null,
  error: false,
};

export const contactReducer = (state, action) => {
  switch (action.type) {
    case "SET_FILE_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        sheetData: action.payload.sheetData,
        sheetJson: action.payload.sheetJson,
        fileName: action.payload.fileName,
        importModal: true,
      };
    case "REMOVE_FILE_DATA":
      return {
        ...state,
        sheetData: [],
        sheetJson: [],
        fileName: null,
      };
    case "CLOSE_IMPORT_MODAL":
      return {
        ...state,
        importModal: false,
      };
    case "SET_CONTACTS":
      return {
        ...state,
        contacts: action.payload,
      };
    case "SET_PAGINATION":
      return {
        ...state,
        paginationCursor: action.payload.paginationCursor,
        totalPages: action.payload.totalPages,
      };
    case "SET_CURRENT_PAGE":
      return {
        ...state,
        currentPage: action.payload,
      };
    default:
      return state;
  }
};

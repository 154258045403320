import { Button, Modal } from "flowbite-react";
import { useContext, useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import Header from "../../components/Header";
import Layout from "../../components/layout/Layout";
import { useStateContext } from "../../contexts/NavProvider";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import io from "socket.io-client";
import { AuthContext } from "../../contexts/AuthContext";
import MessageModal from "../Message/MessageModal";
import { toast } from "react-toastify";
import { FiLogOut } from "react-icons/fi";

function Instance() {
  const socketIORef = useRef();
  const axiosPrivate = useAxiosPrivate();
  const { darkMode } = useStateContext();
  const { auth, instance, setInstance } = useContext(AuthContext);
  // const [instance, setInstance] = useState(null);
  const refreshQr = useRef(false);
  const [qrCode, setQrCode] = useState(null);
  const [qrModal, setQrModal] = useState(false);
  const [trialModal, setTrialModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);

  const checkInstanceStatus = (data) => {
    if (JSON.stringify(data) === "{}") return;
    if (data.hasOwnProperty("connected")) {
      setInstance(data);
      setQrModal(false);
      if (data.connected) {
        clearInterval(refreshQr.current);
        toast.success("Voce esta conectado!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: darkMode ? "dark" : "light",
        });
      }
    } else {
      setTrialModal(true);
    }
  };

  const getInstance = async () => {
    try {
      const response = await axiosPrivate.get(
        `${process.env.REACT_APP_API}/instances`
      );
      console.log(response.data);
      checkInstanceStatus(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenQrModal = async () => {
    const qrCode = await getQrCode();
    setQrCode(qrCode);
    setQrModal(true);
    setRefreshQrCode();
  };

  const getQrCode = async () => {
    try {
      const { data } = await axiosPrivate.get(
        `${process.env.REACT_APP_API}/instances/qr-code`
      );
      console.log(data.value);
      return data.value;
    } catch (err) {
      console.log(err);
    }
  };

  const setRefreshQrCode = async () => {
    const id = setTimeout(async () => {
      const qrCode = await getQrCode();
      console.log("refreshing... ", qrCode);
      setQrCode(qrCode);
      setRefreshQrCode();
    }, 20000);
    refreshQr.current = id;
  };

  const handleDisconnect = async () => {
    try {
      const { data } = await axiosPrivate.get(
        `${process.env.REACT_APP_API}/instances/disconnect`
      );
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const URI =
      process.env.NODE_ENV === "production"
        ? "https://bot.devupsistemas.com.br"
        : process.env.REACT_APP_API;
    socketIORef.current = io(URI, {
      // extraHeaders: { user: auth.user.id },
      // auth: { token: auth.user.id },
      withCredentials: true,
    });
    socketIORef.current.on("connect", () => {
      console.log("connected");
      socketIORef.current?.emit("message", { message: "ola" });
    });

    socketIORef.current.on("update_status", () => {
      console.log("updating status...");
      getInstance();
    });
  }, []);

  useEffect(() => {
    checkInstanceStatus(instance);
  }, []);

  return (
    <Layout>
      <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl dark:bg-slate-700">
        <Header category="Page" title="Instâncias"></Header>
        <div className="mt-12">
          <div className="overflow-hidden  rounded-lg">
            <table className=" divide-gray-200">
              <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left placeholder:uppercase "
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left placeholder:uppercase "
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-right  uppercase "
                  >
                    Acoes
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y">
                {instance && (
                  <tr className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 bg-white dark:border-gray-700 dark:bg-gray-800 text-gray-400">
                    <td className="px-6 py-4">{instance?.name}</td>
                    <td className="px-6 py-4">
                      {instance.connected ? "Conectado" : "Desconectado"}
                    </td>
                    <td className="px-6 py-4">
                      {instance.connected ? (
                        <>
                          <Button
                            class="hidden text-white bg-blue-700 hover:bg-blue-800 rounded-lg text-sm px-2 py-1 ml-3 inline-block"
                            onClick={() => setMessageModal(true)}
                          >
                            Enviar Mensagem
                          </Button>
                          <Button
                            class="inline-block text-white bg-red-500 rounded-lg text-sm px-2 py-1 ml-3"
                            size="sm"
                            onClick={handleDisconnect}
                          >
                            <FiLogOut />
                          </Button>
                        </>
                      ) : (
                        <Button size="sm" onClick={handleOpenQrModal}>
                          Conectar
                        </Button>
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* qrcode modal */}
      <Modal
        className={darkMode ? "dark" : ""}
        show={qrModal}
        onClose={() => setQrModal(false)}
        size="sm"
      >
        <Modal.Header>Conecte-se</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Aponte seu celular para o QrCode e aguarde.
            </p>
            {qrCode && (
              <div style={{ background: "white", padding: "16px" }}>
                <QRCode value={qrCode} />
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button color="gray" onClick={() => setQrModal(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* trial ended */}
      <Modal
        className={darkMode ? "dark" : ""}
        show={trialModal}
        onClose={() => setTrialModal(false)}
        size="md"
      >
        <Modal.Header>Periodo de Testes finalizado!</Modal.Header>
        <Modal.Body>
          <h1 className="text-gray-700 dark:bg-gray-700 dark:text-gray-400">
            Seu periodo de testes acabou! Entre em contato com xxx-xxxxx
          </h1>
        </Modal.Body>
        <Modal.Footer>
          <Button color="gray" onClick={() => setTrialModal(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <MessageModal
        messageModal={messageModal}
        setMessageModal={setMessageModal}
      />
    </Layout>
  );
}

export default Instance;

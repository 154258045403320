import { Button, Label } from "flowbite-react";
import Header from "../../components/Header";
import Layout from "../../components/layout/Layout";
import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useStateContext } from "../../contexts/NavProvider";

const Settings = () => {
  const axios = useAxiosPrivate();
  const { darkMode, setLoading } = useStateContext();
  const [baseCashback, setBaseCashback] = useState("");
  const [saleName, setSaleName] = useState("");
  const [saleCompany, setSaleCompany] = useState("");

  const handleCashbackInput = (e) => {
    const result = e.target.value.replace(/\D/g, "");

    setBaseCashback(result);
  };

  const handleClickUpdateCashback = async (e) => {
    await axios
      .put(`${process.env.REACT_APP_API}/users/updateUserInfo`, {
        base_cashback: parseFloat(baseCashback),
        attendant_name: saleName,
        company_name: saleCompany,
      })
      .then((response) => {
        toast.success("Base de cashback atualizada com sucesso!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: darkMode ? "dark" : "light",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const getUserInfo = async () => {
      await axios
        .get(`${process.env.REACT_APP_API}/users/info`)
        .then((response) => {
          console.log(response.data);
          const user = response.data;
          setBaseCashback(user.base_cashback);
          setSaleName(user.attendant_name);
          setSaleCompany(user.company_name);
        });
    };
    getUserInfo();
  }, []);

  return (
    <Layout>
      <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl dark:bg-slate-700">
        <Header category="Page" title="Configurações"></Header>
        <div className="flex">
          <div className="flex-none mr-4">
            <Label className="mb-2" htmlFor="nome" value="Empresa" />
            <input
              className="w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 rounded-lg p-2.5 text-sm"
              id="saleCompany"
              type="text"
              placeholder="Nome da empresa"
              required={true}
              value={saleCompany}
              onInput={(e) => setSaleCompany(e.target.value)}
            />
          </div>
          <div className="flex-none mr-4">
            <Label className="mb-2" htmlFor="nome" value="Funcionário" />
            <input
              className="w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 rounded-lg p-2.5 text-sm"
              id="saleName"
              type="text"
              placeholder="Nome do funcionário"
              required={true}
              value={saleName}
              onInput={(e) => setSaleName(e.target.value)}
            />
          </div>
          <div className="flex-none mr-4">
            <Label className="mb-2" htmlFor="cashback" value="Cashback base" />
            <input
              className="w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 rounded-lg p-2.5 text-sm"
              id="baseCashback"
              type="text"
              placeholder="Cashback em %"
              required={true}
              maxLength="2"
              value={baseCashback}
              onInput={handleCashbackInput}
            />
          </div>
          <div className="flex mr-4">
            <Button onClick={handleClickUpdateCashback} className="self-end">
              Atualizar
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Settings;

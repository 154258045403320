import { Component } from "react";
import Select, { components } from "react-select";
import "./custom_select.css";

class CustomSelect extends Component {
  state = {
    values: [],
  };

  handleChange = (values) => {
    this.setState({ values });
  };

  render() {
    const { values } = this.state;
    const colourOptions = [
      { value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
      { value: "blue", label: "Blue", color: "#0052CC", disabled: true },
      { value: "purple", label: "Purple", color: "#5243AA" },
      { value: "red", label: "Red", color: "#FF5630", isFixed: true },
      { value: "orange", label: "Orange", color: "#FF8B00" },
      { value: "yellow", label: "Yellow", color: "#FFC400" },
      { value: "green", label: "Green", color: "#36B37E" },
      { value: "forest", label: "Forest", color: "#00875A" },
      { value: "slate", label: "Slate", color: "#253858" },
      { value: "silver", label: "Silver", color: "#666666" },
    ];

    const selectedVals = values.map((x) => x.value);
    const hiddenOptions =
      selectedVals.length > 3 ? selectedVals.slice(0, 3) : [];
    const options = colourOptions.filter(
      (x) => !hiddenOptions.includes(x.value)
    );

    return (
      <div>
        <Select
          closeMenuOnSelect={false}
          isMulti
          options={this.props.options}
          onChange={(e) => this.props.handleOnChange(e)}
          value={this.props.value}
          components={{ MultiValue }}
        />
      </div>
    );
  }
}

export default CustomSelect;

const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: "auto",
    background: "#d4eefa",
    borderRadius: "4px",
    fontFamily: "Open Sans",
    fontSize: "11px",
    padding: "3px",
    order: 99,
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 3;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};

import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import { RequireAuth } from "./contexts/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import User from "./pages/User";
import Message from "./pages/Message";
import Contact from "./pages/Contact";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Instance from "./pages/Instance";
import Loader from "./components/Loader";
import Sale from "./pages/Sale";
import Settings from "./pages/Settings";

function App() {
  return (
    <>
      <Routes>
        {/* public routes */}
        <Route path="/login" element={<Login />} />

        {/* protected routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/users" element={<User />} />
            <Route path="/messages" element={<Message />} />
            <Route path="/instances" element={<Instance />} />
            <Route path="/contacts" element={<Contact />} />
            <Route path="/sales" element={<Sale />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="*" element={<Dashboard />} />
          </Route>
        </Route>
      </Routes>
      <ToastContainer />
      <Loader />
    </>
  );
}

export default App;

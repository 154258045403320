import { Button, Checkbox, Label, Modal, TextInput } from "flowbite-react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useStateContext } from "../../contexts/NavProvider";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function NewSaleModal({ newSaleModal, setNewSaleModal, getSales }) {
  const axios = useAxiosPrivate();
  const { darkMode } = useStateContext();
  const { setLoading } = useStateContext();
  const [cashbackAvailable, setCashbackAvailable] = useState(null);
  const [cashbackAvailableMsg, setCashbackAvailableMsg] = useState("");
  const [cashbackAvailableColor, setCashbackAvailableColor] = useState("gray");

  const [firstSale, setFirstSale] = useState(false);
  const [phone, setPhone] = useState("");

  const [cashbackSpent, setCashbackSpent] = useState("");
  const [cashbackSpentMsg, setCashbackSpentMsg] = useState("");
  const [cashbackSpentColor, setCashbackSpentColor] = useState("gray");

  const [disabledCashback, setDisabledCashback] = useState(false);

  const [total, setTotal] = useState("");

  const brlMask = (e) => {
    let value = e.target.value;
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

    e.target.value = value;
  };

  const validateCashback = (e) => {
    brlMask(e);
    setCashbackSpent(e.target.value);
    const cashbackQt = parseFloat(
      e.target.value.replace(/[^\d,]+/g, "").replace(",", ".")
    );

    if (cashbackQt <= cashbackAvailable) {
      setCashbackSpentColor("success");
    } else {
      setCashbackSpentColor("failure");
    }
    console.log(cashbackQt, cashbackAvailable);
  };

  const handleCloseNewSaleModal = () => {
    setPhone("");
    setTotal("");
    setCashbackSpent("");
    setCashbackAvailableMsg("");
    setCashbackAvailable(null);
    setCashbackSpentColor("gray");
    setCashbackAvailableColor("gray");
    setNewSaleModal(false);
  };

  const checkCashbackMsg = async (e) => {
    const inputPhone = e.target.value;
    setPhone(inputPhone);

    if (inputPhone.length === 11) {
      await axios
        .get(`${process.env.REACT_APP_API}/cashback/findByPhone/${inputPhone}`)
        .then((response) => {
          setDisabledCashback(false);
          setFirstSale(false);
          const cashbackTotal = parseFloat(formatCashbacks(response.data));
          console.log(cashbackTotal);
          setCashbackAvailable(cashbackTotal);
          cashbackTotal > 0
            ? setCashbackAvailableColor("success")
            : setCashbackAvailableColor("failure");

          const cashbackTotalBrl = cashbackTotal.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
          });

          setCashbackAvailableMsg(`Cashback Disponível: ${cashbackTotalBrl}`);
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.status === 404) {
            setFirstSale(true);
            setDisabledCashback(true);
            setCashbackSpent("0");
            setCashbackAvailableMsg("Numero nao cadastrado");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setCashbackAvailableColor("gray");
      setCashbackAvailableMsg("");
      setCashbackAvailable(null);
    }
  };

  const formatCashbacks = (cashbacks) => {
    let total = cashbacks
      .map(
        (cashback) =>
          parseFloat(cashback.total) - parseFloat(cashback.amount_spent)
      )
      .reduce((acc, curr) => acc + curr);

    return total.toString();
  };

  const handleTotal = (e) => {
    brlMask(e);
    setTotal(e.target.value);
  };

  const handleCheckFirstSale = (e) => {
    if (!firstSale) {
      setDisabledCashback(true);
      setCashbackSpent("0");
    } else {
      setDisabledCashback(false);
    }
    setFirstSale(!firstSale);
  };

  const handleSubmit = async () => {
    let saleUrl = `${process.env.REACT_APP_API}/sales`;
    if (firstSale) saleUrl += "/first";

    await axios
      .post(saleUrl, {
        phone: `55${phone}`,
        total: total,
        cashback_spent: cashbackSpent,
      })
      .then((response) => {
        if (response.status == 201) {
          getSales(true);
          setNewSaleModal(false);
          toast.success("Venda criada com sucesso!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: darkMode ? "dark" : "light",
          });
        }
      })
      .finally(() => {
        setPhone("");
        setTotal("");
        setCashbackSpent("");
        setCashbackAvailableMsg("");
        setCashbackAvailable(null);
        setCashbackSpentColor("gray");
        setCashbackAvailableColor("gray");
      });
  };

  return (
    <Modal
      className={
        "flex flex-col w-full min-h-screen overflow-x-hidden" + darkMode
          ? "dark"
          : ""
      }
      size="sm"
      show={newSaleModal}
      onClose={handleCloseNewSaleModal}
    >
      <Modal.Header>Nova Venda</Modal.Header>
      <Modal.Body
        className="block overflow-y-auto"
        style={{ maxHeight: "70vh" }}
      >
        <div className="flex items-center gap-2">
          <Checkbox
            id="firstsale"
            checked={firstSale}
            onChange={(e) => handleCheckFirstSale(e)}
          />
          <Label htmlFor="firstsale">Primeira Venda</Label>
        </div>
        <div className="flex flex-col gap-4 mt-4">
          <div>
            <div className="mb-2 block">
              <Label htmlFor="phone" value="Numero" />
            </div>
            <TextInput
              id="phone"
              type="phone"
              placeholder="Apenas numeros"
              required={true}
              maxLength="11"
              color={cashbackAvailableColor}
              value={phone}
              onInput={(e) => checkCashbackMsg(e)}
              helperText={
                <>
                  <span className="font-medium">{cashbackAvailableMsg}</span>
                </>
              }
            />
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="total" value="Total" />
            </div>
            <TextInput
              id="total"
              type="text"
              value={total}
              onChange={(e) => handleTotal(e)}
              required={true}
            />
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="spent" value="Cashback a utilizar" />
            </div>
            <TextInput
              id="spent"
              type="text"
              value={cashbackSpent}
              disabled={disabledCashback ? true : false}
              onInput={(e) => validateCashback(e)}
              color={cashbackSpentColor}
              helperText={
                <>
                  <span className="font-medium">{cashbackSpentMsg}</span>
                </>
              }
              required={true}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSubmit}>Enviar</Button>
        <Button color="gray" onClick={handleCloseNewSaleModal}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NewSaleModal;

import { Spinner } from "flowbite-react";
import { useEffect } from "react";
import { useStateContext } from "../contexts/NavProvider";

function Loader() {
  const { loading } = useStateContext();

  useEffect(() => {
    console.log("loading", loading);
  }, [loading]);
  return (
    <>
      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
          <h2 className="text-center text-white text-xl font-semibold">
            <Spinner size="xl" aria-label="Left-aligned spinner example" />
          </h2>
          <p className="w-1/3 text-center text-white mt-4">Carregando...</p>
        </div>
      )}
    </>
  );
}

export default Loader;

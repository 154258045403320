import { useContext, useEffect, useReducer, useState } from "react";
import Header from "../../components/Header";
import Layout from "../../components/layout/Layout";
import { useStateContext } from "../../contexts/NavProvider";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { AuthContext } from "../../contexts/AuthContext";
import { saleReducer, INITIAL_STATE } from "./saleReducer";
import { Button, Pagination, Table } from "flowbite-react";
import NewSaleModal from "./NewSaleModal";
import ConfirmSaleModal from "./ConfirmSaleModal";
import { toast } from "react-toastify";

function Sale() {
  const axios = useAxiosPrivate();
  const { darkMode, setLoading } = useStateContext();
  const { auth } = useContext(AuthContext);
  const [state, dispatch] = useReducer(saleReducer, INITIAL_STATE);
  const [newSaleModal, setNewSaleModal] = useState(false);
  const [confirmSaleModal, setConfirmSaleModal] = useState(false);
  const [confirmSale, setConfirmSale] = useState(null);
  const [baseCashback, setBaseCashback] = useState("");
  const [saleName, setSaleName] = useState("");
  const [saleCompany, setSaleCompany] = useState("");

  const getPaginationOptions = (reset) => {
    const options = {
      take: state.perPage,
      id_user: auth.user.id,
    };

    options["skip"] =
      state.currentPage === 1 ? 0 : (state.currentPage - 1) * state.perPage;

    if (state.paginationCursor) options["cursor"] = state.paginationCursor;
    if (reset) options["cursor"] = null;

    return options;
  };

  const getSales = async (reset = false) => {
    console.log(`reset: `, reset);
    console.log(state);

    await axios
      .get(`${process.env.REACT_APP_API}/sales`, {
        params: getPaginationOptions(reset),
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.sales?.length > 0) {
          dispatch({
            type: "SET_SALES",
            payload: response.data.sales,
          });
          if (!state.paginationCursor) {
            dispatch({
              type: "SET_PAGINATION",
              payload: {
                paginationCursor: response.data.sales[0].id,
                totalPages: Math.ceil(response.data.total / state.perPage),
              },
            });
          }
        }
      });
  };

  const handleOnPageChange = async (e) => {
    dispatch({
      type: "SET_CURRENT_PAGE",
      payload: e,
    });
  };

  const handleCashbackInput = (e) => {
    const result = e.target.value.replace(/\D/g, "");

    setBaseCashback(result);
  };

  const handleClickUpdateCashback = async (e) => {
    await axios
      .put(`${process.env.REACT_APP_API}/users/updateUserInfo`, {
        base_cashback: parseFloat(baseCashback),
        attendant_name: saleName,
        company_name: saleCompany,
      })
      .then((response) => {
        toast.success("Base de cashback atualizada com sucesso!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: darkMode ? "dark" : "light",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNewSale = (e) => {
    setNewSaleModal(true);
  };

  const handleConfirmSale = (id) => {
    setConfirmSaleModal(true);
    setConfirmSale(id);
  };

  useEffect(() => {
    getSales();
  }, [state.currentPage]);

  useEffect(() => {
    const getUserInfo = async () => {
      await axios
        .get(`${process.env.REACT_APP_API}/users/info`)
        .then((response) => {
          console.log(response.data);
          const user = response.data;
          setBaseCashback(user.base_cashback);
          setSaleName(user.attendant_name);
          setSaleCompany(user.company_name);
        });
    };
    getUserInfo();
  }, []);

  return (
    <Layout>
      <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl dark:bg-slate-700">
        <Header category="Page" title="Vendas"></Header>

        <div className="mt-12">
          <Button size="sm" onClick={handleNewSale}>
            Nova Venda
          </Button>
        </div>
        <div className="mt-12">
          {state.sales && state.sales.length > 0 && (
            <>
              <div className="flex">
                <Table striped={true}>
                  <Table.Head>
                    <Table.HeadCell>Cliente</Table.HeadCell>
                    <Table.HeadCell>Numero</Table.HeadCell>
                    <Table.HeadCell>Total</Table.HeadCell>
                    <Table.HeadCell>Cashback</Table.HeadCell>
                    <Table.HeadCell>Cashback Utilizado</Table.HeadCell>
                    <Table.HeadCell>Status</Table.HeadCell>
                    <Table.HeadCell>Expira em</Table.HeadCell>
                    <Table.HeadCell>Acoes</Table.HeadCell>
                  </Table.Head>
                  <Table.Body className="divide-y">
                    {state.sales.map((sale, i) => (
                      <Table.Row
                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                        key={i}
                      >
                        <Table.Cell>{sale.customer.name}</Table.Cell>
                        <Table.Cell>{sale.customer.phone}</Table.Cell>
                        <Table.Cell>
                          {parseFloat(sale.total.toString()).toLocaleString(
                            "pt-BR",
                            {
                              style: "currency",
                              currency: "BRL",
                              minimumFractionDigits: 2,
                            }
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {parseFloat(
                            sale.cashback_earned.toString()
                          ).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                            minimumFractionDigits: 2,
                          })}
                        </Table.Cell>
                        <Table.Cell>
                          {parseFloat(
                            sale.cashback_spent.toString()
                          ).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                            minimumFractionDigits: 2,
                          })}
                        </Table.Cell>
                        <Table.Cell>{sale.status}</Table.Cell>
                        <Table.Cell>
                          {sale.confirm_expiration &&
                            new Date(
                              sale.confirm_expiration
                            ).toLocaleDateString("pt-br", {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                        </Table.Cell>
                        <Table.Cell>
                          {sale.status == 1 &&
                            new Date(sale.confirm_expiration) > new Date() && (
                              <Button
                                size="sm"
                                onClick={() => handleConfirmSale(sale.id)}
                              >
                                Confirmar
                              </Button>
                            )}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
              {state.totalPages > 1 && (
                <div className="flex">
                  <Pagination
                    currentPage={state.currentPage}
                    totalPages={state.totalPages}
                    layout="pagination"
                    onPageChange={(e) => handleOnPageChange(e)}
                    showIcons={true}
                    previousLabel="Anterior"
                    nextLabel="Proxima"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <NewSaleModal
        newSaleModal={newSaleModal}
        setNewSaleModal={setNewSaleModal}
        confirmSale={confirmSale}
        getSales={getSales}
      />
      <ConfirmSaleModal
        confirmSaleModal={confirmSaleModal}
        setConfirmSaleModal={setConfirmSaleModal}
        getSales={getSales}
      />
    </Layout>
  );
}

export default Sale;

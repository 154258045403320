import React from "react";

const Header = ({ category, title }: { category: string; title: string }) => {
  return (
    <div className="mb-10">
      {/* <p className="text-gray-400">{category}</p> */}
      <p className="inline-block text-2xl sm:text-3xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200">
        {title}
      </p>
    </div>
  );
};

export default Header;

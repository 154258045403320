import { Link, NavLink } from "react-router-dom";

import {
  MdContactPage,
  MdOutlineCancel,
  MdOutlineConnectedTv,
  MdSpaceDashboard,
  MdSettings,
} from "react-icons/md";
import { Tooltip } from "flowbite-react";

import { useStateContext } from "../contexts/NavProvider";
import { RiMoneyDollarCircleFill } from "react-icons/ri";

import logoWhite from "../assets/images/logo_white.png";
import logoGolden from "../assets/images/logo_golden.png";

const Sidebar = () => {
  const { activeMenu, setActiveMenu, screenSize } = useStateContext();

  const links = [
    {
      title: "",
      links: [
        {
          name: "dashboard",
          path: "dashboard",
          icon: <MdSpaceDashboard />,
        },
      ],
    },

    {
      // title: "Pages",
      links: [
        {
          name: "Vendas",
          path: "sales",
          icon: <RiMoneyDollarCircleFill />,
        },
        {
          name: "Contatos",
          path: "contacts",
          icon: <MdContactPage />,
        },
        {
          name: "Instâncias",
          path: "instances",
          icon: <MdOutlineConnectedTv />,
        },
        {
          name: "Configurações",
          path: "settings",
          icon: <MdSettings />,
        },

        // {
        //   name: "messages",
        //   icon: <AiOutlineMessage />,
        // },
      ],
    },
  ];

  const handleCloseSideBar = () => {
    if (activeMenu && screenSize && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2 bg-sky-500/100";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white";

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link
              to="/"
              onClick={handleCloseSideBar}
              className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
            >
              <img src={logoWhite} className="w-10" />{" "}
              <span>BuildBotBonus</span>
            </Link>
            <Tooltip content="Menu" placement="bottom">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </Tooltip>
          </div>
          <div className="mt-10 ">
            {links.map((item, i) => (
              <div key={i}>
                <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                  {item.title}
                </p>
                {item.links.map((link) => (
                  <NavLink
                    to={`/${link.path}`}
                    key={link.path}
                    onClick={handleCloseSideBar}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    {link.icon}
                    <span className="capitalize ">{link.name}</span>
                  </NavLink>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;

import React, { createContext } from "react";
import { UserAuth } from "../types/User";
import { Instance } from "../types/Instance";

export type AuthContextType = {
  auth: UserAuth;
  instance: Instance;
  setInstance: React.Dispatch<React.SetStateAction<{}>>;
  setAuth: React.Dispatch<React.SetStateAction<{}>>;
  signin: (email: string, password: string) => Promise<boolean>;
  signout: () => void;
};

export const AuthContext = createContext<AuthContextType>(null!);

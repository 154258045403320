import { Outlet } from "react-router";
import { useState, useEffect, useContext } from "react";
import useRefreshToken from "../hooks/useRefreshToken";
import { AuthContext } from "../contexts/AuthContext";
import Cookies from "universal-cookie";

function PersistLogin() {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth, setAuth } = useContext(AuthContext);

  useEffect(() => {
    let isMounted = true;

    // const accessToken = cookies.get("auth.token");
    // if (accessToken) {
    //   setAuth((prev) => {
    //     return {
    //       ...prev,
    //       accessToken,
    //     };
    //   });
    // }

    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        console.log(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    console.log(`is loading ${isLoading}`);
    console.log(`aT: ${JSON.stringify(auth?.accessToken)}`);
  }, [isLoading]);

  return <>{isLoading ? <p>Loading...</p> : <Outlet />}</>;
}

export default PersistLogin;

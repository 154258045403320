import { Button, Label, Modal, TextInput } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/NavProvider";
import Select from "react-select";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import CustomSelect from "../../components/CustomSelect/CustomSelect";

function MessageModal({ messageModal, setMessageModal }) {
  const { darkMode } = useStateContext();
  const axios = useAxiosPrivate();
  const [contacts, setContacts] = useState(null);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [message, setMessage] = useState("");

  const formatContacts = (contacts) => {
    return contacts.map((c) => ({
      value: c.id,
      label: `${c.name} (${c.phone})`,
    }));
  };

  const getContacts = async () => {
    await axios
      .get(`${process.env.REACT_APP_API}/contacts`)
      .then((response) => {
        if (response.data.contacts.length > 0) {
          setContacts(formatContacts(response.data.contacts));
          console.log(response.data.contacts);
        }
      });
  };

  const handleSelectContactChange = (e) => {
    setSelectedContacts(e);
    console.log(selectedContacts.map((contact) => contact.value));
  };

  const handleSubmit = async () => {
    console.log(selectedContacts, message);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/messages`,
        {
          content: message,
          message_type: 1,
          contacts: selectedContacts.map((contact) => contact.value),
        }
      );
      console.log(response);
      if (response.status === 201) {
        toast.success("Mensagem enviada com sucesso!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: darkMode ? "dark" : "light",
        });
      } else {
        toast.error("Falha ao enviar mensagem!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: darkMode ? "dark" : "light",
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setMessageModal(false);
      setSelectedContacts([]);
      setCurrentStep(1);
    }
  };

  const handleOnCloseModal = () => {
    setMessageModal(false);
    setSelectedContacts([]);
    setCurrentStep(1);
  };

  useEffect(() => {
    getContacts();
  }, []);

  return (
    <Modal
      className={darkMode ? "dark" : ""}
      show={messageModal}
      onClose={handleOnCloseModal}
      size="7xl"
    >
      <Modal.Header>
        <ol className="items-center w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0">
          <li className="flex items-center text-blue-600 dark:text-blue-500 space-x-2.5">
            <span className="flex items-center justify-center w-8 h-8 border border-blue-600 rounded-full shrink-0 dark:border-blue-500">
              1
            </span>
            <span>
              <h3 className="font-medium leading-tight">Contatos</h3>
              <p className="text-sm">Selecione os contatos</p>
            </span>
          </li>
          <li className="flex items-center text-gray-500 dark:text-gray-400 space-x-2.5">
            <span className="flex items-center justify-center w-8 h-8 border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
              2
            </span>
            <span>
              <h3 className="font-medium leading-tight">Mensagem</h3>
              <p className="text-sm">Selecione o tipo de mensagem</p>
            </span>
          </li>
        </ol>
      </Modal.Header>
      <Modal.Body>
        {currentStep === 1 && (
          <div>
            <CustomSelect
              options={contacts}
              value={selectedContacts}
              handleOnChange={handleSelectContactChange}
            />
          </div>
        )}
        {currentStep === 2 && (
          <div>
            <div className="mb-2 block">
              <Label htmlFor="message" value="Mensagem" />
            </div>
            <TextInput
              id="message"
              type="text"
              sizing="lg"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button color="gray" onClick={() => setMessageModal(false)}>
          Fechar
        </Button>
        {currentStep === 1 && (
          <Button color="gray" onClick={() => setCurrentStep(currentStep + 1)}>
            Proximo
          </Button>
        )}
        {currentStep === 2 && (
          <Button color="gray" onClick={handleSubmit}>
            Enviar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default MessageModal;

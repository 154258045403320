export const INITIAL_STATE = {
  sales: {},
  currentPage: 1,
  perPage: 10,
  totalPages: 1,
  paginationCursor: null,
  error: false,
};

export const saleReducer = (state, action) => {
  switch (action.type) {
    case "SET_SALES":
      return {
        ...state,
        sales: action.payload,
      };
    case "SET_PAGINATION":
      return {
        ...state,
        paginationCursor: action.payload.paginationCursor,
        totalPages: action.payload.totalPages,
      };
    case "SET_CURRENT_PAGE":
      return {
        ...state,
        currentPage: action.payload,
      };
    default:
      return state;
  }
};

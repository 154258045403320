import axios from "../api/axios";
import useAuth from "./useAuth";

function useRefreshToken() {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/auth/refresh`,
      {
        withCredentials: true,
      }
    );
    console.log("refreshing");

    setAuth((prev) => {
      // console.log(JSON.stringify(prev));
      console.log("rt", response.data);

      return {
        ...prev,
        user: response.data.user,
        roles: response.data.roles,
        accessToken: response.data.accessToken,
      };
    });

    return response.data.accessToken;
  };
  return refresh;
}

export default useRefreshToken;

import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { User as UserType } from "../../types/User";

function User() {
  const [users, setUsers] = useState<UserType[]>();
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get(
          `${process.env.REACT_APP_API}/users`,
          {
            signal: controller.signal,
          }
        );
        console.log(response.data);
        isMounted && setUsers(response.data);
      } catch (err) {
        console.log(err);
      }
    };

    getUsers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <>
      <h1 className="text-3xl font-bold underline">Hello world!</h1>

      <article>
        <h2>Users list</h2>
        {users?.length ? (
          <ul>
            {users.map((user, i) => (
              <li key={i}>{user.email}</li>
            ))}
          </ul>
        ) : (
          <p>No users to display</p>
        )}
      </article>
    </>
  );
}

export default User;

import Header from "../../components/Header";

const Message = () => {
  return (
    <>
      <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl dark:bg-slate-700">
        <Header category="Page" title="Mensagens"></Header>
      </div>
    </>
  );
};

export default Message;

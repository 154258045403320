import { useStateContext } from "../../contexts/NavProvider";
import { Tooltip } from "flowbite-react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";

import { BsFillMoonFill, BsFillSunFill } from "react-icons/bs";

const Layout = ({ children }: any) => {
  const { activeMenu, darkMode, setDarkMode } = useStateContext();
  return (
    <>
      <div className={darkMode ? "dark" : ""}>
        <div className="flex relative dark:bg-slate-700">
          <div
            className="fixed right-4 bottom-4"
            style={{ zIndex: "1000" }}
            onClick={() => setDarkMode((prevDarkMode: any) => !prevDarkMode)}
          >
            <Tooltip content="Dark Mode" placement="top">
              <button
                type="button"
                className="text-3xl p-3 hover:drop-shadow-xl hover:bg-light-gray text-white bg-slate-600 dark:bg-slate-300 dark:text-slate-600"
                style={{ borderRadius: "50%" }}
              >
                {darkMode ? <BsFillMoonFill /> : <BsFillSunFill />}
              </button>
            </Tooltip>
          </div>
          {activeMenu ? (
            <div className="w-72 fixed sidebar dark:bg-slate-800 bg-white">
              <Sidebar />
            </div>
          ) : (
            <div className="w-0  dark:bg-slate-800">
              <Sidebar />
            </div>
          )}
          <div
            className={`dark:bg-main-bg bg-main-bg min-h-screen w-full ${
              activeMenu ? "md:ml-72" : "flex-2"
            }`}
          >
            <div className="fixed md:static bg-main-bg dark:bg-slate-800 navbar w-full">
              <Navbar />
            </div>

            <div>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;

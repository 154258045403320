import React, { useEffect, Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

import { AiOutlineMenu, AiOutlineUser } from "react-icons/ai";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Tooltip } from "flowbite-react";
import { Menu, Transition } from "@headlessui/react";

import Notification from "./Notification";
import UserProfile from "./UserProfile";
import { useStateContext } from "../contexts/NavProvider";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

const NavButton = ({
  title,
  customFunc,
  icon,
  color,
  dotColor,
}: {
  title: string;
  customFunc: any;
  icon: any;
  color: string;
  dotColor?: string;
}) => (
  <Tooltip content={title} placement="bottom">
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </Tooltip>
);

const Navbar = () => {
  const navigate = useNavigate();
  const { setInstance, signout } = useContext(AuthContext);
  const axios = useAxiosPrivate();

  const {
    activeMenu,
    setActiveMenu,
    handleClick,
    isClicked,
    screenSize,
    setScreenSize,
  } = useStateContext();

  useEffect(() => {
    const getInstanceInfo = async () => {
      await axios
        .get(`${process.env.REACT_APP_API}/instances`)
        .then((response) => {
          setInstance(response.data);
        });
    };
    getInstanceInfo();
  }, []);

  const handleSignOut = async () => {
    try {
      await signout();
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize && screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  return (
    <div className="flex justify-between p-2 md:mx-6 relative">
      <NavButton
        title="Menu"
        customFunc={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)}
        color="blue"
        icon={<AiOutlineMenu />}
      />

      <div className="flex">
        {/* <NavButton
          title="Notifications"
          dotColor="#03c9d7"
          customFunc={() => handleClick("notification")}
          color="blue"
          icon={<RiNotification3Line />}
        /> */}

        <Menu as="div" className="relative inline-block text-left ">
          <div>
            <Menu.Button className="inline-flex w-full justify-center rounded-md bg-white dark:bg-slate-800 dark:hover:bg-gray-700 px-4 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
              <div className="flex items-center gap-2 cursor-pointer hover:bg-light-gray rounded-lg">
                <AiOutlineUser className="rounded-full w-8 h-8 bg-white dark:bg-slate-800 dark:hover:bg-gray-700  text-gray-700 hover:bg-gray-50" />
                <p>
                  <span className="text-gray-400 text-14">Bem-vindo</span>{" "}
                  <span className="text-gray-400 font-bold ml-1 text-14">
                    {}
                  </span>
                </p>
                <MdKeyboardArrowDown className="text-gray-400 text-14" />
              </div>
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-slate-800 ">
              <div className="py-1">
                {/* <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      onClick={() => handleClick("userProfile")}
                      className={classNames(
                        active
                          ? "bg-gray-100 text-gray-900 dark:bg-gray-600"
                          : "text-gray-700",
                        "block px-4 py-2 text-sm dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
                      )}
                    >
                      Profile
                    </a>
                  )}
                </Menu.Item> */}

                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="submit"
                      onClick={handleSignOut}
                      className={classNames(
                        active
                          ? "bg-gray-100 text-gray-900 dark:bg-gray-600"
                          : "text-gray-700",
                        "block w-full px-4 py-2 text-left text-sm dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
                      )}
                    >
                      Sair
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>

        {isClicked.notification && <Notification />}
        {isClicked.userProfile && <UserProfile />}
      </div>
    </div>
  );
};

export default Navbar;
